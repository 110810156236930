import { Users } from "../services/Firebase";
import Palette from "../styles/Palette";
import Input from "./Input";
import React, { useMemo } from "react";
import { useAuth } from "../providers/Auth";

export default ({ transport, bottom = true, style, containerStyle }) => {
  const { attributes, assignedAttributes, getAttributeForTransport, user } =
    useAuth();
  const attribute = useMemo(
    () => getAttributeForTransport(transport?.id),
    [transport, attributes, assignedAttributes]
  );
  return (
    <Input
      bottom={bottom}
      dropdown
      items={[
        { name: "Aucun", value: undefined },
        ...attributes.map((a) => ({ name: a.name, value: a.id })),
      ]}
      onChange={(value) => {
        if (value) {
          Users.doc(user.id)
            .collection("assignedAttributes")
            .doc(transport.id)
            .set({ attributeId: value });
        } else {
          Users.doc(user.id)
            .collection("assignedAttributes")
            .doc(transport.id)
            .delete();
        }
      }}
      value={attribute?.id}
      style={{
        backgroundColor: attribute?.color || Palette.white,
        ...style,
        zIndex: 9,
      }}
      containerStyle={containerStyle}
    />
  );
};
