import React from "react";

function TransportStatusBarVehicule({ status }) {
  return (
    <>
      {status === "TRANSPORT_ATTRIBUTED_PENDING" && (
        <img
          width={40}
          height={20}
          src="/icons/veicule_info_modal/suv.svg"
          style={{ position: "absolute", bottom: 29.6, left: 53, zIndex: 100 }}
        />
      )}
      {status === "TRANSPORT_ATTRIBUTED_ACCEPTED" && (
        <img
          width={40}
          height={20}
          src="/icons/veicule_info_modal/suv.svg"
          style={{ position: "absolute", bottom: 29.6, left: 104, zIndex: 100 }}
        />
      )}
      {status === "TRANSPORT_IN_PROGRESS_TO_PATIENT" && (
        <img
          width={40}
          height={20}
          src="/icons/veicule_info_modal/car_trip.svg"
          style={{ position: "absolute", bottom: 29.6, left: 155, zIndex: 100 }}
        />
      )}
      {status === "TRANSPORT_IN_PROGRESS_TO_PLACE" && (
        <img
          width={40}
          height={20}
          src="/icons/veicule_info_modal/car_trip.svg"
          style={{ position: "absolute", bottom: 29.6, left: 205, zIndex: 100 }}
        />
      )}
      {status === "TRANSPORT_ENDED" && (
        <img
          width={40}
          height={20}
          src="/icons/veicule_info_modal/suv.svg"
          style={{ position: "absolute", bottom: 29.6, left: 255, zIndex: 100 }}
        />
      )}
    </>
  );
}

export default TransportStatusBarVehicule;
