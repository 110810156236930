import {
  Card,
  CardDescription,
  CardHeader,
  CardTitle
} from "@components/shadcn/ui/card";
import { ArrowRightIcon } from "@radix-ui/react-icons";
import moment from "moment";
import React from "react";
import { useHistory } from "react-router-dom";
function PatientCard({ patient }) {
  const {
    birthDate,
    socialSecurityNumber,
    phoneNumber,
    firstName,
    lastName,
    id
  } = patient ?? {};
  const navigate = useHistory();
  const handleClick = () => {
    navigate.push(`?patient=${id}`);
  };

  return (
    <Card className="bg-lightGray2 border-4 border-strokGrayLight relative rounded-3xl">
      <CardHeader>
        <CardTitle className="h-auto text-nightBlue text-sm font-bold">
          Patient :
          <span className="!text-pink">{`${firstName} ${lastName}`}</span>
        </CardTitle>
        <CardDescription className="h-auto text-lightBlue">
          Date de naissance :
          <span className="!text-nightBlue">
            {moment(birthDate.toDate()).format("l")}
          </span>
        </CardDescription>
        <CardDescription className="h-auto text-lightBlue">
          N°SS : <span className="!text-nightBlue">{socialSecurityNumber}</span>
        </CardDescription>
        <CardDescription className="h-auto text-lightBlue">
          Numéro <span className="!text-nightBlue">{phoneNumber}</span>
        </CardDescription>
        <button onClick={handleClick}>
          <ArrowRightIcon
            style={{ left: "243px", top:'85px' }}
            className="absolute w-10 h-10  p-2 bottom-8 rounded-lg !bg-skyBlue10 text-skyBlue2"
          />
        </button>
      </CardHeader>
    </Card>
  );
}

export default PatientCard;
