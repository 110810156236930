import React, { useMemo, useState } from "react";
import Palette from "../styles/Palette";
import Colors from "../styles/Colors";

export default function Input({
  required = false,
  fullWidth,
  type = "text",
  onLeft,
  imgLeft,
  iconLeftStyle,
  onRight,
  iconRight,
  iconRightStyle,
  style,
  containerStyle,
  inputContainerStyle,
  inputStyle,
  inputProps,
  placeholder,
  label,
  onChange = () => {},
  value,
  multiline,
  onFocus,
  dropdown,
  bottom,
  items = [],
  height = 40,
}) {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const selected = useMemo(
    () => (dropdown && items?.find((i) => i.value === value)) || undefined,
    [items, value]
  );
  return (
    <div style={{ ...containerStyle }}>
      {label && (
        <div style={{ fontStyle: 15, fontWeight: 500, marginBottom: 5 }}>
          {label} {required && "*"}
        </div>
      )}
      <div
        style={{
          width: fullWidth ? "calc(100% - 2px)" : undefined,
          height: multiline ? height * 2 + 12 : height,
          borderRadius: 5,
          backgroundColor:
            required || dropdown ? Palette.white : Palette.lightGrey,
          border: `1px solid ${Colors.input.border}`,
          display: "flex",
          ...style,
        }}
      >
        {dropdown ? (
          <div
            onClick={(e) => {
              e.preventDefault();
              setDropdownVisible((v) => !v);
            }}
            style={{ display: "flex", position: "relative", flex: 1 }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                alignItems: "center",
                paddingLeft: 15,
                paddingRight: 15,
                cursor: "pointer",
                justifyContent: "space-between",
              }}
            >
              {selected?.name || placeholder || "Sélectionner"}
              <img
                src={"/icons/chevronLeft.png"}
                style={{
                  marginLeft: 10,
                  width: 24,
                  height: 24,
                  transform: "rotate(-90deg)",
                }}
              />
            </div>
            {dropdownVisible && (
              <div
                style={{
                  border: `1px solid ${Colors.input.border}`,
                  minHeight: 50,
                  maxHeight: 50 * 3,
                  backgroundColor: Palette.white,
                  position: "absolute",
                  top: bottom ? undefined : "calc(100% + 5px)",
                  bottom: !bottom ? undefined : "calc(100% + 5px)",
                  borderRadius: 5,
                  left: -1,
                  right: -1,
                  zIndex: 1,
                  overflowY: "auto",
                }}
              >
                {items.map((item) => {
                  const isSelected = item.value === value;
                  return (
                    <div
                      key={item.value}
                      onClick={() => onChange(item.value)}
                      style={{
                        height: 50,
                        display: "flex",
                        paddingLeft: 15,
                        paddingRight: 15,
                        alignItems: "center",
                        backgroundColor: isSelected
                          ? Palette.blueTranslucent
                          : undefined,
                        cursor: "pointer",
                      }}
                    >
                      {item.name}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              paddingLeft: 15,
              paddingRight: 15,
              paddingTop: multiline ? 15 : 0,
              paddingBottom: multiline ? 15 : 0,
              ...inputContainerStyle,
            }}
          >
            {imgLeft && (
              <img
                src={imgLeft}
                style={{
                  width: 24,
                  height: 24,
                  marginRight: 10,
                  ...iconLeftStyle,
                }}
              />
            )}
            {onLeft && onLeft()}
            {multiline ? (
              <textarea
                {...{
                  placeholder,
                  type,
                  value,
                  style: inputStyle,
                  ...inputProps,
                }}
                onChange={(e) => onChange(e.target.value)}
              />
            ) : (
              <input
                {...{
                  placeholder,
                  type,
                  value,
                  onFocus,
                  style: inputStyle,
                  ...inputProps,
                }}
                onChange={(e) => onChange(e.target.value)}
              />
            )}
            {onRight && onRight()}
            {iconRight && (
              <img
                src={iconRight}
                style={{
                  width: 24,
                  height: 24,
                  marginLeft: 10,
                  ...iconRightStyle,
                }}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}
