import { Card } from "@components/shadcn/ui/card";
import moment from "moment";
import React from "react";
function TransportEnded({ transportEnded }) {
  const { carType, startDate, endDate } = transportEnded;
  const { firstName, lastName } = transportEnded.patient;
  const { address: addressFrom } = transportEnded.from;
  const { address: addressTo } = transportEnded.to;
  return (
    <Card className="bg-cosmicLatte border-chateauGreen50 border-2 relative my-3">
      <div className="w-full  flex flex-col px-3">
        <div className="w-full p-3 h-auto rounded-3xl flex flex-row px-3 items-center ">
          <div className="w-2/12  ">
            {carType === "VSL" && (
              <img width={35} src="/icons/veicule_info_modal/vslcar.svg" />
            )}
            {carType === "AMBULANCE" && (
              <img width={35} src="/icons/veicule_info_modal/ambulance.svg" />
            )}
          </div>
          <div className="w-8/12 flex flex-col items-start ">
            <div className="flex ">
              {carType === "AMBULANCE" ? (
                <p
                  style={{
                    height: "fit-content",
                    marginRight: 4,
                    fontSize: 12,
                    fontStyle: "italic",
                    color: "#4951ff",
                    fontWeight: "bold"
                  }}
                >
                  AMB
                </p>
              ) : (
                <p
                  style={{
                    height: "fit-content",
                    marginRight: 4,
                    fontSize: 12,
                    fontStyle: "italic",
                    color: "#FFB960",
                    fontWeight: "bold"
                  }}
                >
                  {carType}
                </p>
              )}

              <p
                style={{
                  height: "fit-content",
                  fontSize: 16,
                  lineHeight: 1,
                  fontWeight: "bold",
                  color: "#02196C"
                }}
              >
                {`${firstName} ${lastName}`}
              </p>
            </div>
            <div>
              <div className="flex flex-row items-baseline">
                {transportEnded?.returnTransportId && (
                  <img width={15} src="/icons/veicule_info_modal/ar.svg" />
                )}
                <p
                  style={{
                    marginRight: 4,
                    fontSize: 12,
                    color: "#02196C60",
                    fontWeight: "bold",
                    fontStyle: "italic"
                  }}
                >
                  Transport <span style={{ color: "#DB2C73" }}> Aller</span>
                </p>
              </div>
            </div>
          </div>
          <div className="w-2/12  h-10 ">
            <div className="flex justify-center">
              <p
                style={{
                  color: "#389D52",
                  fontSize: 12,
                  fontWeight: "bold"
                }}
              >
                Terminé
              </p>
            </div>
          </div>
        </div>
        <div className=" px-3 pb-3 ">
          <div className="flex flex-row">
            <div className="w-10">
              <span className="text-skyBlue2 font-bold text-sm">
                {moment(startDate).format("LT")}
              </span>
            </div>
            <div className="flex">
              <img
                width={15}
                className="mx-2"
                src="/icons/veicule_info_modal/location2.svg"
              />
              <p className="text-skyBlue90 text-sm">{addressFrom}</p>
            </div>
          </div>
          <div className="flex flex-row">
            <div className="w-10">
              <span className="text-pink font-bold text-sm">
                {moment(endDate).format("LT")}
              </span>
            </div>
            <div className="flex">
              <img
                width={15}
                className="mx-2"
                src="/icons/veicule_info_modal/flag.svg"
              />
              <p className="text-pink90  text-sm">{addressTo}</p>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
}

export default TransportEnded;
