import { create } from "zustand";

export const useZustandStore = create()((set) => ({
  isOpen: false,
  setIsOpen: (isOpen) => set({ isOpen }),
  driverID: "",
  setDriverID: (driverID) => set({ driverID }),
  transports: [],
  setTransports: (transports) => set({ transports }),
  allTransports: [],
  setAllTransports: (allTransports) => set({ allTransports }),
  transport: {},
  setTransport: (transport) => set({ transport }),
  transportsEnded: [],
  setTransportsEnded: (transportsEnded) => set({ transportsEnded }),
  transportsFromNotifications: [],
  setTransportsFromNotifications: (transportsFromNotifications) =>
    set({ transportsFromNotifications }),
  tripDistanceAndDuration: {},
  setTripDistanceAndDuration: (tripDistanceAndDuration) =>
    set({ tripDistanceAndDuration }),
  nextDriverTransports: [],
  setNextDriverTransports: (nextDriverTransports) =>
    set({ nextDriverTransports }),
  currentTransportyNotifTime: [],
  setCurrentTransportyNotifTime: (currentTransportyNotifTime) =>
    set({ currentTransportyNotifTime }),
  globalRef: {},
  setGlobalRef: (globalRef) => set({ globalRef }),
  isMapDragEnd: false,
  setIsMapDragEnd: (isMapDragEnd) => set({ isMapDragEnd }),
  reverseGeoCodingAdress: "",
  setReverseGeoCodingAdress: (reverseGeoCodingAdress) =>
    set({ reverseGeoCodingAdress })
}));
