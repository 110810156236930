import { Card } from "@components/shadcn/ui/card";
import moment from "moment";
import React from "react";
function NextTransportsCard({ driverTransport }) {
  const { carType, startDate, endDate, notifCreatedAt, notifType } =
    driverTransport;
  const { firstName, lastName } = driverTransport.patient;
  const { address: addressFrom } = driverTransport.from;
  const { address: addressTo } = driverTransport.to;
  return (
    <Card className="bg-electricIndigo10 border-electricIndigo50 border-2 relative mb-5">
      <div className="w-full  flex flex-col">
        <div className="w-full p-3 h-auto rounded-3xl flex flex-row px-1 items-center ">
          <div className="w-2/12 pl-2">
            {carType === "VSL" && (
              <img width={35} src="/icons/veicule_info_modal/vslcar.svg" />
            )}
            {carType === "AMBULANCE" && (
              <img width={35} src="/icons/veicule_info_modal/ambulance.svg" />
            )}
          </div>
          <div className="w-8/12 flex flex-col items-center">
            <div className="flex">
              {carType === "AMBULANCE" ? (
                <p
                  style={{
                    height: "fit-content",
                    marginRight: 4,
                    fontSize: 12,
                    lineHeight: 1,
                    fontStyle: "italic",
                    color: "#4951ff",
                    fontWeight: "bold"
                  }}
                >
                  AMB
                </p>
              ) : (
                <p
                  style={{
                    height: "fit-content",
                    marginRight: 4,
                    fontSize: 12,
                    fontStyle: "italic",
                    color: "#FFB960",
                    fontWeight: "bold"
                  }}
                >
                  {carType}
                </p>
              )}

              <p
                style={{
                  height: "fit-content",
                  fontSize: 16,
                  lineHeight: 1,
                  fontWeight: "bold",
                  color: "#02196C"
                }}
              >
                {`${firstName} ${lastName}`}
              </p>
            </div>
            <div>
              <div className="flex flex-row items-baseline">
                {driverTransport?.returnTransportId && (
                  <img width={15} src="/icons/veicule_info_modal/ar.svg" />
                )}

                <p
                  style={{
                    marginRight: 4,
                    fontSize: 12,
                    color: "#02196C60",
                    fontWeight: "bold",
                    fontStyle: "italic"
                  }}
                >
                  Transport <span style={{ color: "#DB2C73" }}> Aller</span>
                </p>
              </div>
            </div>
          </div>
          <div className="w-4/12 h-10 items-end">
            <div
              style={{ width: "97px"}}
              className="flex justify-center flex-col pr-3 whitespace-nowrap text-end"
            >
              <p
                style={{
                  color: "#7000FF",
                  fontSize: 12,
                  fontWeight: "bold",
                  textAlign: "end"
                }}
              >
                Attribué
              </p>
              <p
                style={{ fontSize: "11px", lineHeight: 1 }}
                className="font-thin text-electricIndigo text-end"
              >
                {notifType === "TRANSPORT_ATTRIBUTED_ACCEPTED"
                  ? "Accepté à "
                  : "En Attente "}
                {`${moment(notifCreatedAt.toDate()).format("LT")}`}
              </p>
            </div>
          </div>
        </div>
        <div className=" px-3 pb-3">
          <div className="flex flex-row">
            <div className="w-10">
              <span className="text-skyBlue2 font-bold text-sm">
                {moment(startDate.toDate()).format("LT")}
              </span>
            </div>
            <div className="flex">
              <img
                width={15}
                className="mx-2"
                src="/icons/veicule_info_modal/location2.svg"
              />
              <p className="text-skyBlue90 text-sm">{addressFrom}</p>
            </div>
          </div>
          <div className="flex flex-row">
            <div className="w-10">
              <span className="text-pink font-bold text-sm">
                {moment(endDate.toDate()).format("LT")}
              </span>
            </div>
            <div className="flex">
              <img
                width={15}
                className="mx-2"
                src="/icons/veicule_info_modal/flag.svg"
              />
              <p className="text-pink90  text-sm">{addressTo}</p>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
}

export default NextTransportsCard;
