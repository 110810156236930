import {
  assignTransport,
  formatTransportDoc,
  Transports,
  Users
} from "@services/Firebase";
import Colors from "@styles/Colors";
import Global from "@styles/Global";
import { mapStyle } from "@styles/mapStyle";
import Palette from "@styles/Palette";
import firebase from "firebase/compat/app";
import GoogleMapReact from "google-map-react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useDataFromRef from "../../../hooks/useDataFromRef";
// import { toast } from "react-hot-toast";
import Loading from "@components/Loading";
import NewSegmentedControl from "@components/NewSegmentedControl";
import VehiculeModal from "@components/organisms/VehiculeModal";
import DriverPreview from "@components/Previews/DriverPreview";
import PatientPreview from "@components/Previews/PatientPreview";
import RegulatorTransportCard from "@components/RegulatorTransportCard";
import TransportList from "@components/TransportList";
import { useZustandStore } from "@helpers/store";
import LayoutMain from "@layouts/main";
import axios from "axios";
import moment from "moment";
import { setGlobal, useGlobal } from "reactn";
import { toast } from "sonner";
import AttributeSelector from "../../../components/AttributeSelector";
import Button from "../../../components/Button";
import TransportStatusTag from "../../../components/TransportStatusTag";
import { STATUS } from "../../../helpers/status";
import { useAuth } from "../../../providers/Auth";

const NewMap = ({
  style,
  users,
  transportSelected,
  transportToAssign,
  showAvailableUsers,
  showOnInterventionUsers,
  onAssign
}) => {
  const history = useHistory();
  const [map, setMap] = useState();
  const [maps, setMaps] = useState();
  const setTransport = useZustandStore((state) => state.setTransport);
  const setTransportsEnded = useZustandStore(
    (state) => state.setTransportsEnded
  );
  const allTransports = useZustandStore((state) => state.allTransports);
  const setIsOpen = useZustandStore((state) => state.setIsOpen);
  const transportsFromNotifications = useZustandStore(
    (state) => state.transportsFromNotifications
  );
  const setNextDriverTransports = useZustandStore(
    (state) => state.setNextDriverTransports
  );
  const setCurrentTransportyNotifTime = useZustandStore(
    (state) => state.setCurrentTransportyNotifTime
  );
  const setGlobalRef = useZustandStore((state) => state.setGlobalRef);
  const setIsMapDragEnd = useZustandStore((state) => state.setIsMapDragEnd);

  const setReverseGeoCodingAdress = useZustandStore(
    (state) => state.setReverseGeoCodingAdress
  );

  const transport = useZustandStore((state) => state.transport);

  function drawItinary({ directionsDisplay, directionsService }) {
    return new Promise((resolve, reject) => {
      directionsDisplay.setMap(map);
      const origin = {
        lat: transportSelected.from.location?.latitude,
        lng: transportSelected.from.location?.longitude
      };
      const destination = {
        lat: transportSelected.to.location?.latitude,
        lng: transportSelected.to.location?.longitude
      };

      directionsService.route(
        {
          origin,
          destination,
          travelMode: maps.TravelMode.DRIVING
        },
        (result, status) => {
          if (status === maps.DirectionsStatus.OK) {
            directionsDisplay.setDirections(result);
            resolve();
          } else {
            console.error(`error fetching directions ${result}`);
            reject(new Error("error"));
          }
        }
      );
    });
  }

  async function handleMap({ directionsDisplay, directionsService }) {
    const bounds = new maps.LatLngBounds();
    if (transportSelected) {
      await drawItinary({ directionsDisplay, directionsService });
      bounds.extend(
        new maps.LatLng(
          transportSelected.from.location?.latitude,
          transportSelected.from.location.longitude
        )
      );
      bounds.extend(
        new maps.LatLng(
          transportSelected.to.location?.latitude,
          transportSelected.to.location?.longitude
        )
      );
    }
    users.forEach((user) => {
      if (showAvailableUsers && user.interventionOn) return null;
      bounds.extend(
        new maps.LatLng(user.location?.latitude, user.location?.longitude)
      );
      return null;
    });
    map.fitBounds(bounds);
  }

  useEffect(() => {
    if (map && maps && users?.length > 0) {
      const directionsService = new maps.DirectionsService();
      const directionsDisplay = new maps.DirectionsRenderer({
        polylineOptions: {
          strokeWeight: 6,
          strokeColor: Palette.blue,
          zIndex: 99
        }
      });
      handleMap({ directionsDisplay, directionsService });
      return () => directionsDisplay.setMap(null);
    }
  }, [map, maps, users, transportSelected, showAvailableUsers]);

  const mapOptions = {
    fullscreenControl: false,
    zoomControl: false,
    styles: mapStyle
  };
  const handleClick = (event, u) => {
    axios
      .get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${u.location.latitude},${u.location.longitude}&location_type=ROOFTOP&result_type=street_address&key=AIzaSyA9nYMER9hfuG9SIwDT836vfGG4vjzS79o`
      )
      .then((res) => {
        setReverseGeoCodingAdress(res.data.results[0].formatted_address);
      });

    /**************************** 
    Assign transport to the driver
    ****************************/
    showAvailableUsers && onAssign(transportToAssign, u);
    /**************************** 
    Filter transports whith driver ID,
     assigned transport and
    not ended transport status
    ****************************/
    const trs = transportsFromNotifications.filter(
      (item) =>
        item?.driver?.uid === u.id &&
        item.isAssign === true &&
        item.driverStatus !== "ENDED" &&
        (item.notifType === "TRANSPORT_ATTRIBUTED_ACCEPTED" ||
          item.notifType === "TRANSPORT_IN_PROGRESS_TO_PATIENT" ||
          item.notifType === "TRANSPORT_IN_PROGRESS_TO_PLACE") &&
        moment(item.startDate.toDate()).isSameOrAfter(moment())
    );
    /**************************** 
    Filter transports whith driver ID,
     assigned transport and
     ended transport status
    ****************************/
    const trsEnded = allTransports.filter(
      (item) =>
        item?.driver?.uid === u.id &&
        item.isAssign === true &&
        item.driverStatus === "ENDED"
    );
    /**************************** 
    Get NEXT transports whith driver ID,
     PENDING transport STATUS and
    (today date if pending or
     date after the today date(moment())) 
    ****************************/

    const result = transportsFromNotifications.filter(
      (item) =>
        item.assignedTo === u.id &&
        item.isAssign === true &&
        ((item.driverStatus === "PENDING" &&
          item.notifType === "TRANSPORT_ATTRIBUTED_PENDING") ||
          (item.driverStatus === "ACCEPTED" &&
            item.notifType === "TRANSPORT_ATTRIBUTED_ACCEPTED")) &&
        moment(item.startDate.toDate()).isSameOrAfter(moment())
    );
    const currentTransportyNotifTime = transportsFromNotifications.filter(
      (item) =>
        item?.assignedTo === u?.id &&
        item.isAssign === true &&
        item.driverStatus !== "ENDED" &&
        moment(item.startDate.toDate()).isSameOrAfter(moment()) &&
        item?.id === transport?.id
    );

    const res = currentTransportyNotifTime.map((item) => {
      return {
        time: item.notifCreatedAt,
        type: item.notifType,
        idTransport: item.id
      };
    });
    setCurrentTransportyNotifTime(res);
    setNextDriverTransports(result);
    trsEnded && setTransportsEnded(trsEnded);
    trs[0] ? setTransport(trs[0]) : setTransport(null);
    setIsOpen(true);
    setGlobalRef(event.target);
  };
  /**************************************************************
1-TRANSPORT_ACCEPTED ==> Transport created by the regulator ==> driverStatus: null
2-TRANSPORT_ATTRIBUTED_PENDING ==> Transport assigned to the driver ==> driverStatus: "PENDING"
3-TRANSPORT_ATTRIBUTED_ACCEPTED ==> Transport accepted by the driver ==> driverStatus: "ACCEPTED"
4-TRANSPORT_IN_PROGRESS_TO_PATIENT ==> Transport started from driver location to the patient location ==> driverStatus: "RETRIEVING_PATIENT"
5-TRANSPORT_IN_PROGRESS_TO_PLACE ==> Patient transported ==> driverStatus:"DEPOSITING_PATIENT" ==> driverStatus: "DEPOSITING_PATIENT"
6-TRANSPORT_ENDED ==> Patient deposited ==> driverStatus: "ENDED"

 **************************************************************/
  return (
    <GoogleMapReact
      style={style}
      bootstrapURLKeys={{
        key: "AIzaSyA9nYMER9hfuG9SIwDT836vfGG4vjzS79o"
      }}
      defaultCenter={[46.71109, 1.7191036]}
      defaultZoom={7}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={({ map, maps }) => {
        setMap(map);
        setMaps(maps);
        map.addListener("dragend", () => {
          setIsMapDragEnd(true);
        });
        map.addListener("click", () => {
          setIsMapDragEnd(false);
        });
      }}
      options={mapOptions}
    >
      {users.map((u) => {
        return (
          <div
            onClick={(e) => handleClick(e, u)}
            style={{
              position: "relative",
              zIndex: !u.interventionOn ? 1 : 0,
              cursor: showAvailableUsers && "pointer"
            }}
            key={u.id}
            lat={u.location?.latitude}
            lng={u.location?.longitude}
          >
            <img
              src={"/icons/carHorizontal.png"}
              width={62.5}
              height={32}
              style={{
                position: "absolute",
                left: -31.25
              }}
              alt={u.name}
            />

            {!u.interventionOn && showAvailableUsers && (
              <div
                style={{
                  backgroundColor: "white",
                  position: "absolute",
                  bottom: 10,
                  left: -100,
                  right: -100,
                  borderRadius: 5,
                  padding: 10,
                  display: "flex",
                  justifyContent: "space-between",
                  ...Global.bigShadow
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between"
                  }}
                >
                  <div style={{ fontSize: 16, fontWeight: 500 }}>{u.name}</div>
                </div>
              </div>
            )}
            {u.interventionOn && showOnInterventionUsers && (
              <div
                style={{
                  backgroundColor: "white",
                  position: "absolute",
                  bottom: 10,
                  left: -100,
                  right: -100,
                  borderRadius: 5,
                  padding: 10,
                  display: "flex",
                  justifyContent: "space-between",
                  ...Global.bigShadow
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between"
                  }}
                >
                  <div style={{ fontSize: 16, fontWeight: 500 }}>{u.name}</div>
                  <div style={{ fontSize: 14, color: Palette.darkGrey }}>
                    En intervention
                  </div>
                </div>
                <Button
                  onClick={() =>
                    history.push({
                      search: `?transport=${u.interventionOn}`
                    })
                  }
                  variant="secondaryBorder"
                  size="icon"
                  imgLeft={"/icons/chevronLeft.png"}
                  iconStyle={{
                    width: 24,
                    height: 24,
                    transform: "rotate(180deg)"
                  }}
                  containerStyle={{
                    width: 40,
                    height: 40
                  }}
                />
              </div>
            )}
          </div>
        );
      })}

      <div
        style={{
          position: showAvailableUsers ? "absolute" : "relative",
          zIndex: 0,
          top: "-100%",
          left: "-100%",
          bottom: 0,
          right: 0,
          backgroundColor: "rgba(0, 0, 0, 0.3)"
        }}
      />
    </GoogleMapReact>
  );
};

const Place = ({ place }) => {
  return (
    <div
      className={"container flex"}
      style={{ fontSize: 12, justifyContent: "space-between" }}
    >
      <div style={{ fontSize: 17, marginBottom: 5, fontWeight: 500 }}>
        {place.name}
      </div>
      <div>
        {place.address} - {place.postalCode} {place.city}
      </div>
      {place.phoneNumber && <div>Tel : {place.phoneNumber}</div>}
      <div className={"containerRowSpaceBetween"}>
        {[
          place.service,
          place.establishment,
          place.chamber,
          place.complement,
          place.moreNotes
        ].map((v, i) => {
          if (!v) return null;
          return (
            <div style={{ color: Palette.red }} key={i}>
              {v}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const Informations = ({ transport, user }) => {
  const history = useHistory();
  const { data: returnTransport } = useDataFromRef({
    ref:
      !!transport.returnTransportId &&
      Transports.doc(transport.returnTransportId),
    initialState: null,
    simpleRef: true,
    listener: true,
    refreshArray: [transport.returnTransportId],
    condition: !!transport.returnTransportId,
    format: formatTransportDoc
  });
  return (
    <div className={"containerRow flex"}>
      <div
        className={"container flex"}
        style={{
          fontSize: 12,
          justifyContent: "space-between",
          paddingRight: 10,
          boxSizing: "border-box"
        }}
      >
        <div style={{ fontSize: 17, marginBottom: 5, fontWeight: 500 }}>
          Informations
        </div>
        <div style={{ fontSize: 12 }}>{transport.moreNotes}</div>
      </div>
      {returnTransport && (
        <div
          onClick={() =>
            history.push({
              search: `?transport=${transport.returnTransportId}`
            })
          }
          className={"container"}
          style={{
            width: 200,
            backgroundColor: Palette.blueTranslucent,
            margin: -10,
            padding: 10,
            marginLeft: 0,
            boxSizing: "border-box",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: 12,
            cursor: "pointer"
          }}
        >
          <div
            style={{
              fontSize: 17,
              marginBottom: 5,
              fontWeight: 500,
              textAlign: "center"
            }}
          >
            Transport Retour
          </div>
          <div style={{ color: Palette.red }}>
            {moment(returnTransport.startDate).format("dddd DD MMMM")}
          </div>
          <TransportStatusTag transport={returnTransport} user={user} />
        </div>
      )}
    </div>
  );
};

const TransportView = ({ transport, user, style }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  if (!transport) return null;

  return (
    <div
      className={"containerRow"}
      style={{
        position: "absolute",
        left: 20,
        right: 20,
        bottom: 20,
        height: 180,
        ...style
      }}
    >
      <div className={"container"} style={{ flex: 2, marginRight: 20 }}>
        <div
          className={"containerRow"}
          style={{ marginBottom: 10, height: 50, fontSize: 14 }}
        >
          {transport.demDate && (
            <div
              className={"container"}
              style={{
                ...styles.box,
                marginRight: 20,
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <span>
                <b>DEM :&nbsp;</b>
                {moment(transport.demDate).format("HH:mm")}
              </span>
            </div>
          )}
          <div
            className={"containerRow"}
            style={{
              ...styles.box,
              flex: 1,
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <div className={"flex containerRowCenter"}>
              <b>PEC&nbsp;</b>
              <b style={{ color: Palette.red }}>
                {moment(transport.startDate).format("HH:mm")}
              </b>
            </div>
            <img
              src={"/icons/arrow.png"}
              style={{
                marginLeft: 10,
                marginRight: 10,
                width: 12,
                height: 14,
                transform: "rotate(90deg)"
              }}
            />
            <div className={"flex containerRowCenter"}>
              <b style={{ color: Palette.red }}>
                {moment(transport.endDate).format("HH:mm")}
              </b>
              <b>&nbsp;RDV</b>
            </div>
          </div>
          {transport.samuNumber && (
            <div style={{ ...styles.box, marginLeft: 20 }} />
          )}
        </div>
        <div className={"containerRow flex"} style={styles.box}>
          <div
            className={"container"}
            style={{
              boxSizing: "border-box",
              margin: -10,
              marginRight: 10,
              width: 69,
              padding: 10,
              borderRight: `1px solid ${Colors.input.border}`,
              fontSize: 11,
              fontWeight: 700,
              justifyContent: "space-evenly",
              alignItems: "center",
              backgroundColor: Palette.blueTranslucent
            }}
          >
            {["Départ", "Arrivée", "Autres"].map((v, i) => {
              const isSelected = i === selectedTab;
              return (
                <div
                  key={i}
                  style={{ cursor: "pointer", opacity: isSelected ? 1 : 0.5 }}
                  onClick={() => setSelectedTab(i)}
                >
                  {v}
                </div>
              );
            })}
          </div>
          {selectedTab === 0 && <Place place={transport.from} />}
          {selectedTab === 1 && <Place place={transport.to} />}
          {selectedTab === 2 && (
            <Informations transport={transport} user={user} />
          )}
        </div>
      </div>
      <div className={"container"} style={{ marginRight: 20, minWidth: 200 }}>
        <div
          className={"container"}
          style={{
            ...styles.box,
            marginBottom: 10,
            height: 50,
            justifyContent: "center",
            fontSize: 14
          }}
        >
          <span>
            <b>STATUT :&nbsp;</b>
            {STATUS[transport.status].name?.toUpperCase()}
          </span>
        </div>
        <div className={"container flex"} style={styles.box}>
          <DriverPreview {...{ transport }} />
        </div>
      </div>
      <div className={"container"} style={{ flex: 1 }}>
        <AttributeSelector
          transport={transport}
          containerStyle={{ marginBottom: 10 }}
        />
        <div className={"container flex"} style={styles.box}>
          <PatientPreview patient={transport.patient} />
        </div>
      </div>
    </div>
  );
};

export default () => {
  const { uid } = firebase.auth().currentUser;
  const { groups } = useAuth();
  const isOpen = useZustandStore((state) => state.isOpen);
  const setIsOpen = useZustandStore((state) => state.setIsOpen);
  const setTransports = useZustandStore((state) => state.setTransports);
  const transport = useZustandStore((state) => state.transport);
  const setAllTransports = useZustandStore((state) => state.setAllTransports);
  const transportsEnded = useZustandStore((state) => state.transportsEnded);
  const tripDistanceAndDuration = useZustandStore(
    (state) => state.tripDistanceAndDuration
  );
  const setTripDistanceAndDuration = useZustandStore(
    (state) => state.setTripDistanceAndDuration
  );
  const nextDriverTransports = useZustandStore(
    (state) => state.nextDriverTransports
  );
  const globalRef = useZustandStore((state) => state.globalRef);
  const [transportSelected, setTransportSelected] =
    useGlobal("transportSelected");
  const [transportToAssign, setTransportToAssign] =
    useGlobal("transportToAssign");
  const history = useHistory();

  const [type, setType] = useState(0);
  const { data: users, loading } = useDataFromRef({
    ref: Users.where("role", "==", "DRIVER")
      .where("regulatedBy", "==", uid)
      .where("location", "!=", null),
    // format: formatDoc,
    listener: true
  });

  const yesterday = moment().subtract(1, "days").startOf("day").toDate();
  const today = moment().endOf("day").toDate();

  const { data: transports } = useDataFromRef({
    ref: Transports.where("regulatedBy", "==", uid)
      .where("startDate", ">=", yesterday)
      .where("startDate", "<=", today)
      .orderBy("startDate", "asc"),
    format: formatTransportDoc,
    listener: true
  });
  const { data: alltransports } = useDataFromRef({
    ref: Transports.where("regulatedBy", "==", uid),
    format: formatTransportDoc,
    listener: true
  });

  useEffect(() => {
    setTransports(transports);
  }, [transports, isOpen]);
  useEffect(() => {
    setAllTransports(alltransports);
  }, [alltransports, isOpen]);
  // eslint-disable-next-line no-unused-vars
  const { data: bourseTransports } = useDataFromRef({
    ref: Transports.where("regulatedBy", "==", "BOURSE")
      .where("bourseType", "==", "PUBLIC")
      .where("startDate", ">=", moment().toDate())
      .orderBy("startDate", "asc"),
    format: formatTransportDoc,
    listener: true
  });
  const { data: boursePrivateTransports } = useDataFromRef({
    ref:
      (groups || []).length > 0 &&
      Transports.where("regulatedBy", "==", "BOURSE")
        .where("bourseType", "==", "PRIVATE")
        .where(
          "bourseGroup",
          "in",
          groups.map((g) => g.id)
        )
        .where("startDate", ">=", moment().toDate())
        .orderBy("startDate", "asc"),
    format: formatTransportDoc,
    listener: true,
    condition: (groups || []).length > 0
  });

  const transportLists = [
    {
      text: "Tous",
      // data: transports.filter((t) => t.status === "PENDING"),
      data: transports.filter(
        (t) => t.status !== "REFUSED" && t.status !== "ENDED"
      ),
      color: Palette.blue
    },
    {
      text: "En attente",
      data: transports.filter(
        (t) =>
          (t.status === "PENDING" || t.status === "ACCEPTED") && !t.assignedTo
      ),
      color: Palette.blue,
      styles: {
        borderColor: Palette.primary,
        backgroundColor: Palette.secondary
      }
    },
    {
      text: "Attribué",
      data: transports.filter(
        (t) =>
          !!t.assignedTo &&
          t.status !== "ENDED" &&
          t.status !== "IN_PROGRESS" &&
          t.status !== "REFUSED" &&
          t.status !== "CANCELED"
      ),
      color: Palette.blue
    },

    {
      text: "En cours",
      data: transports.filter(
        (t) =>
          !!t.assignedTo &&
          !!t.driverStatus &&
          t.driverStatus !== "ENDED" &&
          t.status !== "ENDED" &&
          t.status !== "ACCEPTED" &&
          t.status !== "REFUSED" &&
          t.status !== "CANCELED"
      ),
      color: Palette.salmon,
      styles: {
        borderColor: Palette.secondary,
        backgroundColor: Palette.secondary
      }
    },
    {
      text: "Bourse",
      data: [
        ...boursePrivateTransports.filter(
          (t) => t.status === "PENDING" || t.status === "ACCEPTED"
        ),
        ...bourseTransports.filter(
          (t) => t.status === "PENDING" || t.status === "ACCEPTED"
        )
      ],
      color: Palette.yellow
    }
  ];

  async function calculateDistanceAndDuration() {
    const { address: fromAddress } = transport.from ?? "chicago, il";
    const { address: toAddress } = transport.to ?? "st louis, mo";
    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService();
    directionsService
      .route({
        origin: {
          query: fromAddress
        },
        destination: { query: toAddress },
        travelMode: "DRIVING"
      })
      .then((response) => {
        setTripDistanceAndDuration({
          distance: response.routes[0].legs[0].distance,
          duration: response.routes[0].legs[0].duration
        });
      })
      .catch((e) => console.log("==>$error", e));
  }
  useEffect(() => {
    isOpen && transport && calculateDistanceAndDuration();
  }, [isOpen]);

  return (
    <LayoutMain>
      <div
        className={"containerRow"}
        style={{
          height: "calc(100vh - 100px)",
          marginBottom: -20,
          display: "flex",
          justifyContent: "flex-end",
          marginRight: "48px",
          marginTop: "48px"
        }}
      >
        {/* <div
          className={"flex container"}
          style={{
            position: "relative",
          }}
        > */}
        <NewMap
          {...{ users }}
          showAvailableUsers={!!transportToAssign}
          showOnInterventionUsers={!transportSelected}
          transportSelected={transportSelected}
          transportToAssign={transportToAssign}
          style={{ height: "100vh" }}
          onAssign={async (transport, user) => {
            await assignTransport(
              transport.id,
              {
                demDate: transport.demDate,
                assignedTo: user.id
              },
              transport,
              user
            );
            setTransportSelected(null);
            setTransportToAssign(null);
            toast.success(`Course acceptée & assignée à ${user.name}`);
          }}
        />
        {/* TODO: ticket de ARILES - [ ] - Dans l’onglet « carte », est-ce que tu peux enlever toutes les informations / bulles qui sont en bas de la carte, j’ai l’impression que ça gène plus qu’autre chose pour le moment. Si tu peux juste les cacher, on verra par la suite si c’est vraiment utile en utilisant le logiciel. */}
        {/* <TransportView
          style={{ height: 150, width: "calc(100% - 54%)" }}
          transport={transportSelected}
          user={uid}
        /> */}
        {/* </div> */}

        <div
          className={"container"}
          style={{
            position: "absolute",
            left: 100,
            zIndex: 1,
            marginLeft: 20,
            borderRadius: 10,
            width: "350px",
            height: "533px",
            backgroundColor: "white",
            padding: 20,
            boxSizing: "border-box",
            ...Global.bigShadow
          }}
        >
          <div style={{ marginBottom: 10 }}>
            <div style={{ fontSize: 24, fontWeight: 700, marginBottom: 10 }}>
              Transports
            </div>
            <NewSegmentedControl
              items={transportLists}
              selected={type}
              onSelect={(i) => {
                setType(i);
              }}
            />
          </div>
          <div
            className={"container flex"}
            style={{
              overflowY: "auto",
              scrollbarColor: "transparent transparent",
              scrollbarWidth: "none"
            }}
          >
            <TransportList
              containerStyle={{ flex: 1 }}
              Component={RegulatorTransportCard}
              isBourse={type === 4}
              transports={transportLists[type]?.data}
              selected={transportSelected}
              setSelected={setTransportSelected}
              onClick={(t) => {
                setTransportSelected(t);
                setTransportToAssign(null);
              }}
              onCancel={() => {
                setTransportSelected(null);
              }}
              onAccept={(t) => setGlobal({ transportToAssignAndChangePEC: t })}
              onAssign={(t) => setGlobal({ transportToAssignAndChangePEC: t })}
              onRefuse={(t) => setGlobal({ transportToRefuse: t })}
              onCancelOrBourse={(t) => {
                setGlobal({ transportToCancelOrBourse: t });
              }}
            />
          </div>
        </div>
        {console.log("==>ù", transport)}
        {isOpen &&
        transport &&
        transport.notifType !== "TRANSPORT_ATTRIBUTED_ACCEPTED" ? (
          <VehiculeModal
            isOpen={isOpen}
            transport={{ ...transport, tripDistanceAndDuration }}
            transportsEnded={transportsEnded}
            nextDriverTransports={nextDriverTransports}
            handleClick={() => setIsOpen(false)}
            except={globalRef}
          />
        ) : transportsEnded ? (
          <VehiculeModal
            isOpen={isOpen}
            transportsEnded={transportsEnded}
            handleClick={() => setIsOpen(false)}
          />
        ) : (
          <VehiculeModal
            isOpen={isOpen}
            noInfo={true}
            handleClick={() => setIsOpen(false)}
          />
        )}
        {loading && <Loading absolute />}
      </div>
    </LayoutMain>
  );
};

const styles = {
  box: {
    ...Global.box,
    backgroundColor: Palette.white,
    padding: 10
  }
};
