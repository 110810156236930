"use client";

import * as React from "react";
import { format, addMonths, subYears } from "date-fns";
import { fr } from "date-fns/locale";
import {
  Calendar as CalendarIcon,
  ChevronLeft,
  ChevronRight,
} from "lucide-react";
import { cn } from "../shadcn/lib/utils";

import { Popover, PopoverContent, PopoverTrigger } from "../shadcn/ui/popover";
import { Button } from "../shadcn/ui/button";
import { Calendar } from "../shadcn/ui/calendar";

export function DatePicker({
  date,
  setDate,
  placeholder,
  className,
  disableYearSelection = false,
  enableDateRange = false,
  dateRange,
  setDateRange,
}) {
  const [month, setMonth] = React.useState(date ? new Date(date) : new Date());
  const [isYearView, setIsYearView] = React.useState(false);
  const [yearRange, setYearRange] = React.useState([]);
  const yearViewRef = React.useRef(null);

  const currentYear = new Date().getFullYear();
  const minYear = currentYear - 99;

  React.useEffect(() => {
    const endYear = currentYear;
    const startYear = Math.max(endYear - 11, minYear);
    setYearRange(Array.from({ length: 12 }, (_, i) => startYear + i).reverse());
  }, [currentYear, minYear]);

  React.useEffect(() => {
    function handleClickOutside(event) {
      if (yearViewRef.current && !yearViewRef.current.contains(event.target)) {
        setIsYearView(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleMonthChange = (newMonth) => {
    setMonth(newMonth);
  };

  const handleYearClick = () => {
    if (!disableYearSelection) {
      setIsYearView(!isYearView);
    }
  };

  const handleYearSelect = (year) => {
    const newDate = new Date(year, month.getMonth());
    handleMonthChange(newDate);
    setIsYearView(false);
  };

  const handleYearRangeChange = (direction) => {
    const currentEndYear = yearRange[0];
    let newEndYear;
    if (direction === "next") {
      newEndYear = Math.min(currentEndYear + 12, currentYear);
    } else {
      newEndYear = Math.max(currentEndYear - 12, minYear + 11);
    }
    const newStartYear = Math.max(newEndYear - 11, minYear);
    setYearRange(
      Array.from({ length: 12 }, (_, i) => newStartYear + i).reverse()
    );
  };

  const formatSelectedDate = () => {
    if (enableDateRange && dateRange?.from && dateRange?.to) {
      return `${format(dateRange.from, "dd/MM/yyyy")} - ${format(
        dateRange.to,
        "dd/MM/yyyy"
      )}`;
    } else if (date) {
      return format(date, "dd/MM/yyyy");
    }
    return <span>{placeholder}</span>;
  };

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          className={cn(
            "w-full justify-start text-left font-normal",
            !date && !dateRange && "text-muted-foreground",
            className
          )}
        >
          {formatSelectedDate()}
          <CalendarIcon className="ml-auto h-4 w-4" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" align="start">
        {isYearView && !disableYearSelection ? (
          <div className="p-2" ref={yearViewRef}>
            <div className="flex justify-between items-center mb-2">
              <Button
                variant="outline"
                size="icon"
                onClick={() => handleYearRangeChange("prev")}
              >
                <ChevronLeft className="h-4 w-4" />
              </Button>
              <span>{`${yearRange[yearRange.length - 1]} - ${
                yearRange[0]
              }`}</span>
              <Button
                variant="outline"
                size="icon"
                onClick={() => handleYearRangeChange("next")}
              >
                <ChevronRight className="h-4 w-4" />
              </Button>
            </div>
            <div className="grid grid-cols-3 gap-2">
              {yearRange.map((year) => (
                <Button
                  key={year}
                  onClick={() => handleYearSelect(year)}
                  variant={year === month.getFullYear() ? "default" : "outline"}
                >
                  {year}
                </Button>
              ))}
            </div>
          </div>
        ) : (
          <Calendar
            mode={enableDateRange ? "range" : "single"}
            selected={enableDateRange ? dateRange : date}
            onSelect={enableDateRange ? setDateRange : setDate}
            initialFocus
            month={month}
            onMonthChange={handleMonthChange}
            components={{
              Caption: ({ displayMonth }) => (
                <div className="flex justify-between items-center px-2 py-1">
                  <Button
                    variant="outline"
                    size="icon"
                    className="h-7 w-7"
                    onClick={() =>
                      handleMonthChange(addMonths(displayMonth, -1))
                    }
                  >
                    <ChevronLeft className="h-4 w-4" />
                  </Button>
                  <div
                    className="flex items-center space-x-1"
                    style={{ justifyContent: "center" }}
                  >
                    <span
                      className="text-sm font-medium"
                      style={{ textTransform: "capitalize" }}
                    >
                      {format(displayMonth, "MMMM", { locale: fr })}
                    </span>
                    <span
                      className={cn(
                        "text-sm font-medium",
                        !disableYearSelection &&
                          "border border-input px-1 rounded cursor-pointer"
                      )}
                      onClick={handleYearClick}
                    >
                      {displayMonth.getFullYear()}
                    </span>
                  </div>
                  <Button
                    variant="outline"
                    size="icon"
                    className="h-7 w-7"
                    onClick={() =>
                      handleMonthChange(addMonths(displayMonth, 1))
                    }
                  >
                    <ChevronRight className="h-4 w-4" />
                  </Button>
                </div>
              ),
            }}
            locale={fr}
          />
        )}
      </PopoverContent>
    </Popover>
  );
}
