import { Card, CardHeader } from "@components/shadcn/ui/card";
import { REASONS } from "@data/transports";
import { capitalize } from "lodash-es";
import moment from "moment";
import React from "react";
import { useHistory } from "react-router-dom";
function TransportCard({ transport }) {
  const { returnTransportId, startDate, endDate, reason } = transport;
  const { name: fromName, address: fromAddress } = transport.from ?? "";
  const { name: toName, address: toAddress } = transport.to ?? "";
  const { text: distance } = transport.tripDistanceAndDuration.distance ?? {};
  const { text: duration } = transport.tripDistanceAndDuration.duration ?? {};
  const { value: durationInSencode } =
    transport.tripDistanceAndDuration.duration ?? {};

  // Calculate expected arrival time
  const expectedArrivalTime = moment(startDate.toDate())
    .add({ seconds: durationInSencode })
    .format("LT");
  const reasonFr = () => {
    return REASONS.filter((item) => item.value === reason)[0].name;
  };
  //Show transport details
  const history = useHistory();
  const handleTransportDetails = () => {
    history.push({
      search: `?transport=${transport.id}`
    });
  };
  return (
    <Card className="relative border-none">
      <CardHeader className="px-0">
        <div className="flex flex-row justify-between h-auto text-lightBlue text-sm font-bold italic">
          <div>
            Transport
            <span className="!text-pink">
              {returnTransportId ? " Aller - Retour" : " Aller"}
            </span>
          </div>
          <div onClick={handleTransportDetails} className="cursor-pointer">
            <img
              width={17}
              src="/icons/veicule_info_modal/transport_info.svg"
            />
          </div>
        </div>
        <div className="mt-0">
          <p className="text-nightBlue font-bold">
            {capitalize(moment(startDate.toDate()).format("dddd DD MMMM"))}
          </p>
          <p className="text-lightBlue text-sm font-bold italic">
            Pris en charge pour
            <span className="!text-pink"> {capitalize(reasonFr())}</span>
          </p>
        </div>
        <div className="flex flex-row w-full">
          <div>
            <p style={{ fontSize: 12 }} className="!text-pink font-bold">
              {moment(startDate.toDate()).format("LT")}
            </p>
            {returnTransportId ? (
              <img
                className="m-auto"
                height={20}
                src="icons/veicule_info_modal/ar_icon.svg"
              />
            ) : (
              <img
                className="m-auto"
                height={20}
                src="icons/veicule_info_modal/a_icon.svg"
              />
            )}
            <p style={{ fontSize: 12 }} className="!text-pink font-bold">
              {moment(endDate.toDate()).format("LT")}
            </p>
          </div>
          <div className="flex flex-col pl-2">
            <div className="h-1/2">
              <p style={{ color: "#02196c", fontSize: 13 }}>{fromName}</p>
              <p style={{ color: "#02196c60", fontSize: 11 }}>{fromAddress}</p>
            </div>
            <div className="flex flex-col h-1/2 justify-end">
              <p style={{ color: "#02196c", fontSize: 13 }}>{toName}</p>
              <p style={{ color: "#02196c60", fontSize: 11 }}>{toAddress}</p>
            </div>
          </div>
        </div>
        <div>
          <p className="text-lightBlue italic font-semibold text-center whitespace-pre text-sm">{`Estimation : ~ ${duration} & ${distance} de route\n Arrivée prévu à ${expectedArrivalTime}`}</p>
        </div>
      </CardHeader>
    </Card>
  );
}

export default TransportCard;
