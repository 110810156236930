import Drawer from "@components/Drawer/Drawer";
import TabBar from "@components/TabBar";
import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import "./style.css";

import Grid from "@mui/material/Grid2";
export const useGutters = (noPadding) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const calcGutters = isTabletOrMobile ? 50 : 100;
  return !noPadding ? calcGutters : 0;
};

export default function LayoutMain({
  containerStyle = {},
  style,
  children,
  noPadding = false,
  onRight
}) {
  const gutters = useGutters(noPadding);
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const handleOpenDrawer = () => {
    setDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  };

  return (
    <Grid container>
      <Grid
        size={1}
        sx={{
          position: "absolute",

          zIndex: 10,

          display: "flex",

          height: "100%",

          alignItems: "center",

          justifyContent: "center",

          width: "80px",

          marginLeft: "16px"
        }}
      >
        <TabBar onNotifyClick={handleOpenDrawer} />

        <Drawer isOpen={isDrawerOpen} onClose={handleCloseDrawer} />
      </Grid>

      <Grid size={1}></Grid>

      <Grid size={11}>{children}</Grid>
    </Grid>
  );
}
