import React, { useEffect, useState } from "react";
import Dropdown from "../Dropdown";
import Global from "../../styles/Global";
import Checkbox from "../Checkbox";

// EXEMPLE DE DONNÉES

// const notificationStatus = [
//   { name: "Toutes les notifications", value: ["ALL"] },
//   { name: "Refusé", value: ["REFUSED"] },
//   { name: "Terminé", value: ["ENDED"] },
//   {
//     name: "Nouveau",
//     value: ["PENDING"],
//   },
//   { name: "Bourse", value: ["BOURSE"] },
//   {
//     name: "Annulé",
//     value: ["CANCELED"],
//   },
//   { name: "A réguler", value: ["ACCEPTED"] },
//   { name: "Attribué", value: ["ASSIGNED"] },
//   { name: "En cours", value: ["IN_PROGRESS"] },
// ];

// EXEMPLE DE DONNÉES <FIN>

export default ({ containerStyle, selected, setSelected, data = [] }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);

  // useEffect(() => {
  //   console.log(selected);
  // }, [selected]);

  const handleInputChange = (status) => {
    setSelected(status);
  };

  return (
    <div
      className={"flex"}
      style={{
        ...Global.item,
        position: "relative",
        maxWidth: 320,
        border: "2px solid",
        borderColor: "rgba(222, 224, 243, 1)",
        backgroundColor: "rgba(246, 247, 255, 1)",
        ...containerStyle,
      }}
    >
      <div
        className={"flex containerRowCenter"}
        onClick={() => setDropdownVisible((v) => !v)}
        style={{
          cursor: "pointer",
          marginLeft: 16,
          justifyContent: "flex-start",
        }}
      >
        <div>{selected?.name}</div>
        <img
          src={"/icons/chevronLeft.png"}
          style={{
            position: "absolute",
            top: "calc(50% - 12px)",
            bottom: 0,
            right: 10,
            alignItems: "center",
            width: 24,
            height: 24,
            transform: "rotate(-90deg)",
          }}
        />
      </div>
      <Dropdown visible={dropdownVisible}>
        {data?.map((status, i) => {
          const isLast = i === data?.length - 1;
          return (
            <div
              onClick={() => setSelected(status)}
              className={"containerRow"}
              style={{
                cursor: "pointer",
                marginBottom: isLast ? 0 : 8,
                alignItems: "center",
              }}
              key={status?.value}
            >
              {/* <input
                style={{ marginRight: 8, width: 12, height: 12 }}
                type="checkbox"
                checked={
                  Array.isArray(selected?.value)
                    ? selected?.value.includes(status?.value[0])
                    : selected?.value[0] === status?.value[0]
                }
                onChange={() => handleInputChange(status)}
              /> */}
              <Checkbox
                value={
                  Array.isArray(selected?.value)
                    ? selected?.value.includes(status?.value[0])
                    : selected?.value[0] === status?.value[0]
                }
                onChange={() => handleInputChange(status)}
                name={status?.name}
                style={{ marginRight: 8 }}
              />
            </div>
          );
        })}
      </Dropdown>
    </div>
  );
};
