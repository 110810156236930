/**************************************************************
1-TRANSPORT_ACCEPTED ==> Transport created by the regulator ==> driverStatus: null
2-TRANSPORT_ATTRIBUTED_PENDING ==> Transport assigned to the driver ==> driverStatus: "PENDING"
3-TRANSPORT_ATTRIBUTED_ACCEPTED ==> Transport accepted by the driver ==> driverStatus: "ACCEPTED"
4-TRANSPORT_IN_PROGRESS_TO_PATIENT ==> Transport started from driver location to the patient location ==> driverStatus: "RETRIEVING_PATIENT"
5-TRANSPORT_IN_PROGRESS_TO_PLACE ==> Patient transported ==> driverStatus:"DEPOSITING_PATIENT" ==> driverStatus: "DEPOSITING_PATIENT"
6-TRANSPORT_ENDED ==> Patient deposited ==> driverStatus: "ENDED"
 **************************************************************/
import { Badge } from "@components/shadcn/ui/badge";
import { useZustandStore } from "@helpers/store";
import moment from "moment";
import React, { useEffect } from "react";
import TransportStatusBarVehicule from "./TransportStatusBarVehicule";
function TransportStatusBar({ transport }) {
  const currentTransportyNotifTime = useZustandStore(
    (state) => state.currentTransportyNotifTime
  );
  currentTransportyNotifTime.sort(
    (a, b) => a.time.valueOf() - b.time.valueOf()
  );
  const TRANSPORT_ACCEPTED_TIME = currentTransportyNotifTime[1]?.time.toDate();

  const TRANSPORT_ATTRIBUTED_PENDING_TIME =
    currentTransportyNotifTime[2]?.time.toDate();

  const TRANSPORT_ATTRIBUTED_ACCEPTED_TIME =
    currentTransportyNotifTime[3]?.time.toDate();

  const TRANSPORT_IN_PROGRESS_TO_PATIENT_TIME =
    currentTransportyNotifTime[4]?.time.toDate();

  const TRANSPORT_IN_PROGRESS_TO_PLACE_TIME =
    currentTransportyNotifTime[5]?.time.toDate();
  const ENDED_TIME = currentTransportyNotifTime[6]?.time.toDate();
  return (
    <div className="relative flex flex-row">
      {/*******************************
       * Manage the vehicule position *
       ******************************/}
      <TransportStatusBarVehicule status={transport?.notifType} />
      <Badge
        index="1"
        className={`${
          transport?.notifType === "TRANSPORT_ATTRIBUTED_PENDING" ||
          transport?.notifType === "TRANSPORT_ATTRIBUTED_ACCEPTED" ||
          transport?.notifType === "TRANSPORT_IN_PROGRESS_TO_PATIENT" ||
          transport?.notifType === "TRANSPORT_IN_PROGRESS_TO_PLACE"
            ? "stepBarCompleted"
            : "stepBarBasicBg"
        } h-1 w-40`}
        stepStyle={`${
          (transport?.notifType === "TRANSPORT_ATTRIBUTED_PENDING" ||
            transport?.notifType === "TRANSPORT_ATTRIBUTED_ACCEPTED" ||
            transport?.notifType === "TRANSPORT_IN_PROGRESS_TO_PATIENT" ||
            transport?.notifType === "TRANSPORT_IN_PROGRESS_TO_PLACE") &&
          "stepBarCompleted"
        } stepBarBasic`}
        time={
          TRANSPORT_ACCEPTED_TIME
            ? moment(TRANSPORT_ACCEPTED_TIME).format("hh:mm")
            : ""
        }
        message="Envoyé"
        textStyle={`${
          transport?.notifType === "TRANSPORT_ATTRIBUTED_PENDING" ||
          transport?.notifType === "TRANSPORT_ATTRIBUTED_ACCEPTED" ||
          transport?.notifType === "TRANSPORT_IN_PROGRESS_TO_PATIENT" ||
          transport?.notifType === "TRANSPORT_IN_PROGRESS_TO_PLACE"
            ? "text-nightBlue"
            : "text-shadyGray"
        } text-shadyGray`}
      />
      <Badge
        index="2"
        className={`${
          transport?.notifType === "TRANSPORT_ATTRIBUTED_ACCEPTED" ||
          transport?.notifType === "TRANSPORT_IN_PROGRESS_TO_PATIENT" ||
          transport?.notifType === "TRANSPORT_IN_PROGRESS_TO_PLACE"
            ? "stepBarCompleted"
            : "stepBarBasicBg"
        } h-1 w-10 !left-7`}
        stepStyle={`${
          transport?.notifType === "TRANSPORT_ATTRIBUTED_ACCEPTED" ||
          transport?.notifType === "TRANSPORT_IN_PROGRESS_TO_PATIENT" ||
          transport?.notifType === "TRANSPORT_IN_PROGRESS_TO_PLACE"
            ? "stepBarCompleted stepBarRounded"
            : " stepBarRounded"
        } stepBarBasic`}
        time={
          TRANSPORT_ATTRIBUTED_PENDING_TIME
            ? moment(TRANSPORT_ATTRIBUTED_PENDING_TIME).format("hh:mm")
            : ""
        }
        message="Acquitté"
        textStyle={`${
          transport?.notifType === "TRANSPORT_ATTRIBUTED_ACCEPTED" ||
          transport?.notifType === "TRANSPORT_IN_PROGRESS_TO_PATIENT" ||
          transport?.notifType === "TRANSPORT_IN_PROGRESS_TO_PLACE"
            ? "text-nightBlue"
            : "text-shadyGray"
        } text-shadyGray`}
      />
      <Badge
        index="3"
        className={`${
          transport?.notifType === "TRANSPORT_IN_PROGRESS_TO_PATIENT" ||
          transport?.notifType === "TRANSPORT_IN_PROGRESS_TO_PLACE"
            ? transport?.notifType === "TRANSPORT_IN_PROGRESS_TO_PATIENT"
              ? "stepBarActive"
              : "stepBarCompleted"
            : "stepBarBasicBg"
        } h-1 w-10 !left-7`}
        stepStyle={`${
          transport?.notifType === "TRANSPORT_IN_PROGRESS_TO_PATIENT" ||
          transport?.notifType === "TRANSPORT_IN_PROGRESS_TO_PLACE"
            ? transport?.notifType === "TRANSPORT_IN_PROGRESS_TO_PATIENT"
              ? "stepBarActive stepBarRounded"
              : "stepBarCompleted stepBarRounded"
            : " stepBarRounded"
        } stepBarBasic`}
        time={
          TRANSPORT_ATTRIBUTED_ACCEPTED_TIME
            ? moment(TRANSPORT_ATTRIBUTED_ACCEPTED_TIME).format("hh:mm")
            : ""
        }
        message={`Début\nMission`}
        textStyle={`${
          transport?.notifType === "TRANSPORT_IN_PROGRESS_TO_PATIENT" ||
          transport?.notifType === "TRANSPORT_IN_PROGRESS_TO_PLACE"
            ? transport?.notifType === "TRANSPORT_IN_PROGRESS_TO_PATIENT"
              ? "text-skyblue"
              : "text-nightBlue"
            : "text-shadyGray"
        } text-shadyGray`}
      />
      <Badge
        index="4"
        className={`${
          transport?.notifType === "TRANSPORT_IN_PROGRESS_TO_PLACE"
            ? transport?.notifType === "TRANSPORT_IN_PROGRESS_TO_PLACE"
              ? "stepBarActive"
              : "stepBarCompleted"
            : "stepBarBasicBg"
        } h-1 w-10 !left-7`}
        stepStyle={`${
          transport?.notifType === "TRANSPORT_IN_PROGRESS_TO_PLACE"
            ? transport?.notifType === "TRANSPORT_IN_PROGRESS_TO_PLACE"
              ? "stepBarActive stepBarRounded"
              : "stepBarCompleted stepBarRounded"
            : " stepBarRounded"
        } stepBarBasic`}
        time={
          TRANSPORT_IN_PROGRESS_TO_PATIENT_TIME
            ? moment(TRANSPORT_IN_PROGRESS_TO_PATIENT_TIME).format("hh:mm")
            : ""
        }
        message={`Patient\nRécupéré`}
        textStyle={`${
          transport?.notifType === "TRANSPORT_IN_PROGRESS_TO_PLACE"
            ? transport?.notifType === "TRANSPORT_IN_PROGRESS_TO_PLACE"
              ? "text-skyblue"
              : "text-nightBlue"
            : "text-shadyGray"
        } text-shadyGray`}
      />
      <Badge
        index="5"
        className={`${
          transport?.notifType === "TRANSPORT_ENDED"
            ? transport?.notifType === "TRANSPORT_ENDED"
              ? "stepBarActive"
              : "stepBarCompleted"
            : "stepBarBasicBg"
        } h-1 w-10 !left-7`}
        stepStyle={`${
          transport?.notifType === "TRANSPORT_ENDED"
            ? transport?.notifType === "TRANSPORT_ENDED"
              ? "stepBarActive stepBarRounded"
              : "stepBarCompleted stepBarRounded"
            : " stepBarRounded"
        } stepBarBasic`}
        time={
          TRANSPORT_IN_PROGRESS_TO_PLACE_TIME
            ? moment(TRANSPORT_IN_PROGRESS_TO_PLACE_TIME).format("hh:mm")
            : ""
        }
        message={`Vers\nlieu RDV`}
        textStyle={"text-shadyGray"}
      />
      <Badge
        index="6"
        className="h-1 w-12  stepBarBasicBg"
        stepStyle="stepBarBasic"
        time={ENDED_TIME ? moment(ENDED_TIME).format("hh:mm") : ""}
        message={`Terminé`}
        textStyle={"text-shadyGray"}
      />
      {/* <ProgressV2 value={100} /> */}
    </div>
  );
}

export default TransportStatusBar;
