import TransportCard from "@components/organisms/TransportCard";
import { Card } from "@components/shadcn/ui/card";
import { Separator } from "@components/shadcn/ui/separator";
import { useZustandStore } from "@helpers/store";
import { useClickOutside } from "@hooks/useClickOutside";
import moment from "moment";
import React, { useRef } from "react";
import VisibilitySensor from "react-visibility-sensor";
import NextTransportsCard from "./NextTransportsCard";
import PatientCard from "./PatientCard";
import TransportEnded from "./TransportEnded";
import TransportStatusBar from "./TransportStatusBar";
function VehiculeModal({
  isOpen,
  transport,
  handleClick,
  transportsEnded,
  nextDriverTransports
}) {
  const { patient } = transport ?? {};
  const { carType, name } = transport?.driver ?? "";
  const { carType: _carType, name: _name } = transportsEnded[0]?.driver ?? "";
  const ref = useRef(null);
  const globalRef = useZustandStore((state) => state.globalRef);
  const reverseGeoCodingAdress = useZustandStore(
    (state) => state.reverseGeoCodingAdress
  );
  useClickOutside(ref, handleClick, globalRef);

  transportsEnded.sort((a, b) => b.startDate.valueOf() - a.startDate.valueOf());
  return (
    <div
      ref={ref}
      className="bg-background z-10  rounded-3xl border-solid border-4 border-borderV2 custom-scrollbar px-5 box-border"
      style={{
        display: `${isOpen ? "block" : "none"}`,
        width: "350px"
      }}
    >
      <div className="w-full pt-5 h-auto rounded-3xl flex flex-row  items-start mb-3">
        <div className="w-2/12 my-auto">
          {(carType || _carType) && (
            <img width={35} src="/icons/veicule_info_modal/vslcar.svg" />
          )}
        </div>
        <div className="w-6/12 flex flex-col">
          <div style={{ lineHeight: 1 }} className="flex w-full justify-start">
            <p
              style={{
                marginRight: 4,
                fontSize: 13,
                color: "#FFB960",
                fontStyle: "italic",
                alignSelf: "flex-end"
              }}
            >
              {transport ? carType : _carType}
            </p>
            <p
              style={{
                fontSize: 16,
                fontWeight: "bold",
                color: "#02196C"
              }}
            >
              {transport ? name : _name}
            </p>
          </div>
          <div
            className="flex justify-start w-full items-end"
            style={{ lineHeight: 1 }}
          >
            <p
              style={{
                fontSize: 12,
                color: "#02196C60",
                fontWeight: "bold",
                height: "fit-content"
              }}
            >
              John.D - <span style={{ color: "#DB2C73" }}>Samy.A</span>
            </p>
          </div>
        </div>
        <div className="h-full">
          <div
            className="flex justify-center border w-24"
            style={{
              border: "solid 1px #DEE0F3",
              borderRadius: 10,
              backgroundColor: "#F6F7FF"
            }}
          >
            {transport?.notifType === "TRANSPORT_IN_PROGRESS_TO_PATIENT" ||
            transport?.notifType === "TRANSPORT_IN_PROGRESS_TO_PLACE" ? (
              <>
                <img
                  width={10}
                  src="/icons/veicule_info_modal/dot_red.svg"
                  className="mr-1"
                />
                <p
                  style={{
                    color: "#FFBBC2",
                    marginLeft: 2,
                    fontSize: 12,
                    fontWeight: "bold"
                  }}
                >
                  En course
                </p>
              </>
            ) : (
              <>
                <img
                  width={10}
                  src="/icons/veicule_info_modal/dot_blue.svg"
                  className="mr-1"
                />
                <p
                  style={{
                    color: "#506BFF",
                    marginLeft: 2,
                    fontSize: 11,
                    fontWeight: "bold"
                  }}
                >
                  Disponible
                </p>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="flex py-3 flex-col mt-2">
        <div style={{ width: "302px" }} className="w-full flex mt-5">
          <TransportStatusBar transport={transport} />
        </div>
      </div>
      <div className="flex flex-row justify-center mb-5">
        <img width={20} src="/icons/veicule_info_modal/location.svg" />
        <p className="text-xs text-lightBlue pl-2 self-end font-bold">
          {reverseGeoCodingAdress}
        </p>
      </div>
      <div className={!transport && `custom-scrollbar h-3/4`}>
        {transportsEnded &&
          !transport &&
          transportsEnded.map((item, i) => {
            const prevStartDate =
              i > 0 ? transportsEnded[i - 1].startDate : null;
            const isSameAsPrevDay =
              prevStartDate &&
              moment(prevStartDate).isSame(item.startDate, "d");
            return (
              <div style={{ width: "302px" }} key={item.id}>
                {!isSameAsPrevDay && (
                  <VisibilitySensor offset={{ top: 300 }} partialVisibility>
                    <div
                      className="capitalize"
                      id={moment(item.startDate).format("YYYY-MM-DD")}
                      style={{
                        fontSize: 17,
                        fontWeight: "bold",
                        marginBottom: 10,
                        marginTop: i !== 0 ? 5 : 0,
                        color: "#02196C"
                      }}
                    >
                      {moment(item.startDate).format("dddd DD MMMM YYYY")}
                    </div>
                  </VisibilitySensor>
                )}
                <TransportEnded key={item.id} transportEnded={item} />
              </div>
            );
          })}
      </div>
      {transport && (
        <>
          <div style={{ width: "302px" }} className="rounded-3xl">
            <PatientCard patient={patient} />
          </div>
          <div className="rounded-3xl m-auto">
            <TransportCard transport={transport} />
          </div>
          <Separator className="mx-auto w-5/6 h-0.5 bg-borderV2" />
          <div className="m-auto custom-scrollbar h-2/4">
            <Card className="relative  border-none">
              <p className="m-3 text-nightBlue font-bold text-lg text-center">
                Prochains transports
              </p>
              {nextDriverTransports &&
                nextDriverTransports.map((item, i) => {
                  const prevStartDate =
                    i > 0
                      ? nextDriverTransports[i - 1].startDate.toDate()
                      : null;
                  const isSameAsPrevDay =
                    prevStartDate &&
                    moment(prevStartDate).isSame(item.startDate.toDate(), "d");
                  return (
                    <div key={item.id}>
                      {!isSameAsPrevDay && (
                        <VisibilitySensor
                          offset={{ top: 300 }}
                          partialVisibility
                        >
                          <div
                            className="capitalize"
                            id={moment(item.startDate.toDate()).format(
                              "YYYY-MM-DD"
                            )}
                            style={{
                              fontSize: 17,
                              fontWeight: "bold",
                              marginBottom: 10,
                              marginTop: i !== 0 ? 5 : 0,
                              color: "#02196C"
                            }}
                          >
                            {moment(item.startDate.toDate()).format(
                              "dddd DD MMMM YYYY"
                            )}
                          </div>
                        </VisibilitySensor>
                      )}
                      <NextTransportsCard driverTransport={item} />
                    </div>
                  );
                })}
            </Card>
          </div>
        </>
      )}
    </div>
  );
}

export default VehiculeModal;
