import { Stack, Typography } from "@mui/material";
import { useAuth } from "@providers/Auth";
import Palette from "@styles/Palette";
import {
  tabBarContainer,
  tabBarLogoStyle,
  tabBarMenu,
  tabBarMenuItem,
  tabBarNotifAndExitContainer,
  tabBarNotificationContainer,
  tabBarSubContainer
} from "@styles/tabBarSyle";
import React, { useMemo } from "react";
import { NavLink, useHistory, useRouteMatch } from "react-router-dom";
import Button from "./Button";
export default function TabBar({ gutters = 50, onNotifyClick }) {
  const { user, signOut, notifications } = useAuth();
  const history = useHistory();
  const routes = useMemo(
    () => [
      {
        route: "/map",
        name: "Map",
        img: "icons/sidebar_menu/siren.svg",
        condition: user?.role === "REGULATOR"
      },
      {
        route: "/transports",
        name: "Régulation",
        img: "icons/sidebar_menu/star.svg",
        condition: user?.role === "REGULATOR"
      },
      {
        route: "/patients",
        name: "Patients",
        img: "icons/sidebar_menu/patient.svg"
      },
      {
        route: "/vehicule",
        name: "Véhicules",
        img: "icons/sidebar_menu/vehicule.svg"
      },
      {
        route: "?editGroup=true",
        name: "Groupes",
        img: "icons/sidebar_menu/group.svg"
      },
      {
        route: "/dashboard",
        name: "Tableau de bord",
        img: "icons/sidebar_menu/dashboard.svg",
        condition: user?.role === "HOSPITAL"
      },
      {
        route: "?editTransport=new",
        name: "Nouveau transport",
        img: "icons/sidebar_menu/add.svg"
      }
      // {
      //   route: "/planning",
      //   name: "Planning",
      //   img: "icons/sidebar_menu",
      // },
    ],
    [user?.role]
  );
  return (
    <div style={tabBarContainer}>
      <div style={tabBarSubContainer(gutters)}>
        <div>
          <NavLink to="/map">
            <img style={tabBarLogoStyle} src="icons/logo.svg" />
          </NavLink>
        </div>

        <div style={tabBarMenu}>
          {routes
            .filter(({ condition = true }) => condition)
            .map(({ route, name, img }) => {
              const match = useRouteMatch({
                path: route,
                exact: false
              });
              return (
                <Stack
                  key={route}
                  style={tabBarMenuItem(match, Palette)}
                  onClick={(e) => {
                    history.replace(route);
                  }}
                >
                  {img && (
                    <Button
                      imgLeft={match ? `${img.slice(0, -4)}_selected.svg` : img}
                      iconStyle={{ width: 28, height: 28 }}
                      variant="secondary"
                      containerStyle={{
                        background: "none"
                      }}
                    />
                  )}
                  <Typography fontSize={12} sx={{ textAlign: "center" }}>
                    {name}
                  </Typography>
                </Stack>
              );
            })}
        </div>
        <Stack style={tabBarNotifAndExitContainer}>
          <div style={tabBarNotificationContainer}>
            <Button
              onClick={onNotifyClick}
              imgLeft={"icons/sidebar_menu/notif.svg"}
              iconStyle={{ width: 28, height: 28 }}
              variant="secondary"
              containerStyle={{
                background: "none"
              }}
            />
            <div
              style={{
                position: "absolute",
                top: 2,
                right: 17,
                pointerEvents: "none"
              }}
            >
              {notifications?.length > 0 && (
                <div
                  style={{
                    width: 16,
                    height: 16,
                    borderRadius: "50%",
                    backgroundColor: Palette.red,
                    fontSize: 12,
                    color: "white",
                    textAlign: "center",
                    alignContent: "center"
                  }}
                >
                  {notifications?.length}
                </div>
              )}
            </div>
          </div>
          <Stack>
            <img
              src="icons/sidebar_menu/exit.svg"
              width={28}
              height={28}
              onClick={signOut}
              style={{ paddingTop: 16, margin: "auto", cursor: "pointer" }}
            />
            <Typography
              sx={{
                color: "#DB2C7360",
                fontSize: 12,
                paddingTop: 1,
                cursor: "pointer"
              }}
              onClick={signOut}
            >
              Déconnexion
            </Typography>
          </Stack>
        </Stack>
      </div>
    </div>
  );
}
