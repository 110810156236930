const Palette = {
  white: "white",
  black: "black",
  grey: "#EFEFEF",
  lightGrey: "#F8F8F8",
  darkGrey: "#8d8d8d",
  red: "#DB2C73",
  salmon: "#F28992",
  lightSalmon: "rgba(255, 187, 194, 0.1)",
  salmonTranslucent: "rgba(242,137,146,0.08)",
  redTranslucent: "rgba(219,44,115,0.08)",
  green: "#34C759",
  lightGreen: "rgba(52,199,89,0.1)",
  greenTranslucent: "rgba(52,199,89,0.08)",
  blue: "#506BFF",
  blueSemiTranslucent: "rgba(80,107,255,0.2)",
  blueTranslucent: "rgba(55,125,255,0.08)",
  yellow: "#B4C028",
  lightYellow: "rgba(180,192,40,0.1)",
  yellowTranslucent: "rgba(180,192,40,0.2)",
  purple: "rgba(112, 0, 255, 1)",
  lightPurple: "rgba(112, 0, 255, 0.1)",
  purpleTranslucent: "rgba(206,167,255,0.2)",
  gray8: "rgba(222, 224, 243, 1)",
  gray9: "rgba(118, 118, 128, 0.12)",
  // HOSTAL FACTURATION
  background: "#FDFDFD",

  // black: '#000000',
  // white: '#FFFFFF',
  whiteTranslucent: "rgba(255,255,255,0.5)",

  halfGrey: "#E0E0ED",
  // grey: '#A3A6A9',
  // darkGrey: '#494952',

  blueGrey: "#606EA6",

  lightBlue: "#F6F7FF",
  halfBlue: "#ECEEFD",
  // blue: '#506BFF',
  darkBlue: "#02196C",

  extraLightRed: "#FFFAFE",
  lightRed: "#FFDADE",
  halfRed: "#FFE8F1",
  // red: '#DB2C73',

  halfGreen: "#EFFBF2",
  // green: '#389D52',

  halfOrange: "rgba(255,102,69,0.14)",
  orange: "#FF6645",
};

export default Palette;
