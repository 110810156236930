import React from "react";
import LayoutMain from "../../../layouts/main";
import Palette from "../../../styles/Palette";
import { useMediaQuery } from "react-responsive";
import Global from "../../../styles/Global";
import TransportList from "../../../components/TransportList";
import useDataFromRef from "../../../hooks/useDataFromRef";
import Loading from "../../../components/Loading";
import firebase from "firebase/compat/app";
import { useAuth } from "../../../providers/Auth";
import moment from "moment";
import Calendar from "../../../components/Calendar/Transports";
import { formatTransportDoc, Transports } from "../../../services/Firebase";

function Events({ transports }) {
  return (
    <div className={"container flex"}>
      <h1 style={{ marginBottom: 10 }}>Transports à venir</h1>
      <TransportList {...{ transports }} />
    </div>
  );
}

export default function Dashboard() {
  const { user } = useAuth();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const { uid } = firebase.auth().currentUser;
  const { data: transports, loading } = useDataFromRef({
    ref: Transports.where("createdBy", "==", uid)
      .where("startDate", ">=", moment().toDate())
      .orderBy("startDate"),
    format: formatTransportDoc,
    listener: true,
  });

  return (
    <LayoutMain>
      <div
        style={{
          justifyContent: "space-between",
          display: "flex",
          flexDirection: isTabletOrMobile ? "column" : "row",
          boxSizing: "border-box",
          paddingTop: 20,
          minHeight: "calc(100vh - 120px)",
        }}
      >
        <div
          style={{
            ...Global.containerItem,
            width: isTabletOrMobile ? "calc(100% - 20px)" : 300,
            marginRight: isTabletOrMobile ? 0 : 10,
            marginBottom: isTabletOrMobile ? 10 : 0,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className={"container flex"} style={{ marginBottom: 20 }}>
            <Events
              {...{
                transports: transports.filter(
                  (t) =>
                    ![
                      "CANCELED",
                      "REFUSED",
                    ].includes(t.status)
                ),
              }}
            />
            {loading && (
              <Loading
                absolute
                containerStyle={{ backgroundColor: "rgba(255, 255, 255, 0.5)" }}
              />
            )}
          </div>
          <div
            style={{
              backgroundColor: Palette.blueTranslucent,
              borderRadius: 5,
              padding: 10,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            {user?.regulator && (
              <div style={{ display: "flex" }}>
                <div>
                  <div style={{ fontWeight: 600, marginBottom: 5 }}>
                    {user.regulator.name}
                  </div>
                  <div style={{ fontSize: 14 }}>
                    {user.regulator.address.address},{" "}
                    {user.regulator.address.postalCode}{" "}
                    {user.regulator.address.city}
                  </div>
                  <div style={{ fontSize: 14 }}>
                    {user.regulator.phoneNumber}
                  </div>
                  <div
                    onClick={(e) => {
                      window.location = `mailto:${user.regulator.mail}`;
                      e.preventDefault();
                    }}
                    style={{
                      fontSize: 14,
                      fontWeight: 500,
                      cursor: "pointer",
                    }}
                  >
                    {user.regulator.mail}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div style={{ ...Global.containerItem, flex: 1 }}>
          <Calendar />
        </div>
      </div>
    </LayoutMain>
  );
}
